import Lucky from "../Assets/Lucky.jpeg";
import Mukesh from "../Assets/Mukesh.jpeg";

const Instructor = [
  {
    profileImage: Lucky,
    profileName: "Lucky Kumar",
    company: "Google",
    role: "Software Engineer",
    company_logo: "https://www.vectorlogo.zone/logos/google/google-icon.svg",
    expertise: [
      "Learn directly from DSA whiz and a Googlean himself! With 4+ years guiding 20,000+ learners, he turns complexity into clarity.",
    ],
    linkedin: "https://www.linkedin.com/in/lucky-kumar-a06338153/",
  },
  {
    profileImage: Mukesh,
    profileName: "Mukesh Gupta",
    company: "Microsoft",
    role: "Software Engineer",
    company_logo:
      "https://www.vectorlogo.zone/logos/microsoft/microsoft-icon.svg",
    companyImage:
      "https://www.bosscoderacademy.com/Images/Companies/microsoft.svg",
    expertise: [
      "Meet our Microsoft insider! Straight from the interview battlefield, he'll help you “crack the code”, quite literally.",
    ],
    linkedin: "https://www.linkedin.com/in/mukeshgupta097",
  },
];

export { Instructor };
