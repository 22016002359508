const Courses = [
  {
    title: "Basic to Advanced DSA",
    isActive: true,
    prodMRP: 20000,
    prodSP: 15000,
    discount: "25%",
    concepts: [
      "Introduction to Algorithms",
      "Complexity Analysis",
      "Basic Data Structures",
      "Trees and Graphs",
      "Sorting Algorithms",
      "Search Algorithms",
      "+8 more",
    ],
  },
  {
    title: "System Design",
    isActive: false,
    prodMRP: 20000,
    prodSP: 15000,
    discount: "25%",
    concepts: [
      "Architectural Foundations and Design Principles",
      "Scalability and Performance Optimization",
      "Database Design and Data Modeling",
      "Distributed Systems and Microservices Architecture",
      "Network and Security Considerations in System Design",
      "Cloud Computing and DevOps Integration",
      "+11 more",
    ],
  },
];

export { Courses };
