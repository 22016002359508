export default function Whatsapp() {
  return (
    <div>
      <a
        href="https://wa.me/+918700352711?text=I%20want%20to%20join%20your%20course."
        target="_blank"
        rel="noreferrer"
        className="whatsapp_icon z-20 fs-3"
      >
        <img
          src="https://www.vectorlogo.zone/logos/whatsapp/whatsapp-tile.svg"
          alt="Whatsapp"
          style={{ width: "50px" }}
          className="rounded-circle shadow"
        />
      </a>
    </div>
  );
}
