import "../../Styles/instructors.scss";

export default function Instructors({ instructor }) {
  const {
    profileImage,
    profileName,
    company,
    role,
    company_logo,
    expertise,
    linkedin,
  } = instructor;
  return (
    <div className="instructor_card">
      <div className="instructor_img">
        <img
          src={profileImage}
          alt={profileName}
          className="instructor_picture"
        />
        <div className="instructor_company">
          <img src={company_logo} alt={company} />
        </div>
      </div>

      <div className="instrucor_name">{profileName}</div>
      <div className="mb-2">{role}</div>
      <p className="expertise">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#4395f5"
          className="bi bi-quote mr-1"
          viewBox="0 0 16 16"
        >
          <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
        </svg>
        {expertise}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#4395f5"
          className="bi bi-quote inverted_quote ml-1"
          viewBox="0 0 16 16"
        >
          <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
        </svg>
      </p>
      <a
        href={linkedin}
        target="_blank"
        rel="noreferrer"
        alt="Linkedin"
        className="linkedin_link"
      >
        Linkedin Profile
      </a>
    </div>
  );
}
