import React from "react";
import { Card, Image } from "react-bootstrap";

const ContainerCard = (props) => {
  return (
    <div style={{ marginBottom: "2rem" }}>
      <Card
        style={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Card.Body className="d-flex flex-column">
          <div className="d-flex flex-row">
            <div className="border border-primary h-fit my-auto rounded-circle p-1">
              <Image
                fluid
                roundedCircle
                style={{ width: "70px" }}
                src={props.profileReview.profileImage}
              />
            </div>

            <div className="ml-2 container">
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{
                  width: "100%",
                  fontWeight: 600,
                  marginTop: "0.2rem",
                  fontSize: "20px",
                }}
              >
                <div className="d-flex flex-column">
                  <div>{props.profileReview.profileName}</div>
                  <div style={{ fontWeight: 400, fontSize: "16px" }}>
                    {props.profileReview.position}
                  </div>
                </div>

                <img
                  src={props.profileReview.company_logo}
                  alt={props.profileReview.company}
                  style={{ width: "30px", height: "30px" }}
                  className="ml-5"
                />
              </div>
            </div>
          </div>

          {props.profileReview.expertise !== undefined &&
            props.profileReview.expertise.map((expertise, index) => {
              return (
                <label
                  key={index}
                  style={{
                    padding: "0.5rem",
                    fontWeight: 400,
                  }}
                  className="text-secondary"
                >
                  {expertise}
                </label>
              );
            })}

          <blockquote className="blockquote p-1 m-0" style={{ fontSize: 15 }}>
            {props.profileReview.review}
          </blockquote>
          {props.profileReview.linkedin && (
            <a
              href={props.profileReview.linkedin}
              target="_blank"
              rel="noreferrer"
              variant="outline-secondary"
              style={{ width: "100%" }}
              className="btn btn-outline-secondary"
            >
              Linkedin Profile
            </a>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ContainerCard;
