import React from "react";
import ContainerCard from "./ContainerCard";
import { Row, Col } from "react-bootstrap";
import { Reivews } from "../../DataSource/Reviews";
import "../../Styles/base.scss";
import { CustomSlider } from "../Slider";

const ReviewContainer = () => {
  return (
    <div className="reviewContainer">
      <Row>
        <Col sm={12} md={12} lg={12}>
          <div style={{ padding: "1rem" }}>
            <h2
              style={{
                color: "#27374D",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              Hear what our Learners have to say
            </h2>
            <h3
              style={{
                color: "#27374D",
                textAlign: "center",
                fontWeight: 250,
              }}
            >
              Learn how Codebix helped them achieve their dream careers
            </h3>
          </div>
        </Col>
      </Row>

      <Row>
        <CustomSlider>
          {Reivews !== undefined &&
            Reivews.map((review, index) => (
              <Col sm={12} md={12} lg={12} key={index}>
                <ContainerCard key={index} profileReview={review} />
              </Col>
            ))}
        </CustomSlider>
      </Row>
    </div>
  );
};

export default ReviewContainer;
