import Rechin from "../Assets/reviews/Rechin.jpeg";
import Akshay from "../Assets/reviews/Akshay.jpeg";
import Rishay from "../Assets/reviews/Rishay.jpeg";
import Bhushan from "../Assets/reviews/Bhushan.jpeg";
import Padamani from "../Assets/reviews/Padamani.jpeg";
import Swapnil from "../Assets/reviews/Swapnil.jpeg";

const Reivews = [
  {
    profileName: "Padamani",
    profileImage: Padamani,
    position: "Software Engineer 2",
    company: "Microsoft",
    company_logo:
      "https://www.vectorlogo.zone/logos/microsoft/microsoft-icon.svg",
    review:
      "Thanks to Codebix, I mastered my DSA game and made the switch to a MAANG company. The practical insights and guidance made all the difference.",
    linkedin: "https://www.linkedin.com/in/padminikota/",
  },
  {
    profileName: "Swapnil",
    profileImage: Swapnil,
    position: "Senior Software Engineer",
    company: "Qualys",
    company_logo:
      "https://companieslogo.com/img/orig/QLYS-68c2032c.png?t=1648731661",
    review:
      "The DSA course was a game-changer for me. I got a 200% salary hike with my job switch and the investment was worth every penny. Couldn't be happier with the instructors.",
    linkedin: "https://www.linkedin.com/in/swapnil-patil-9609b3131/",
  },
  {
    profileName: "Bhushan",
    profileImage: Bhushan,
    position: "Senior MTS",
    company: "Salesforce",
    company_logo:
      "https://www.vectorlogo.zone/logos/salesforce/salesforce-ar21.svg",
    review:
      "The instructors at Codebix are amazing. Learning directly from professionals at my dream companies gave me an edge. Their insider tips on cracking interviews were spot-on.",
    linkedin: "https://www.linkedin.com/in/advirkarbhushan/",
  },
  {
    profileName: "Rishay",
    profileImage: Rishay,
    position: "SDE",
    company: "Deloitte",
    company_logo:
      "https://pbs.twimg.com/profile_images/1635503425227206657/8ko8kied_400x400.png",
    review:
      "As a fresher, I had no interview experience. Codebix's DSA course equipped me with the skills and confidence I needed for my interviews. I landed my dream job right after completing the course.",
    linkedin: "https://www.linkedin.com/in/rishay-shandilya-b13856198",
  },
  {
    profileName: "Rechin",
    profileImage: Rechin,
    position: "Senior VP",
    company: "Deutsche Bank",
    company_logo:
      "https://logo-download.com/wp-content/data/images/svg/Deutsche-Bank-logo.svg",
    review:
      "Lucky is an absolutely phenomenal teacher. He breaks down complex topics into fun and easy concepts.  It's a joy being taught by him every time. ",
    linkedin: "https://www.linkedin.com/in/rechin-raj-00a8b099/",
  },
  {
    profileName: "Akshay Wadkar",
    profileImage: Akshay,
    position: "SDE",
    company: "JPMC",
    company_logo:
      "https://logos-world.net/wp-content/uploads/2020/11/Chase-Emblem.png",
    review:
      "The instructors at Codebix don't just solve questions; they teach with a problem-solving approach. I can now confidently tackle any coding puzzle or challenge that comes my way. ",
    linkedin: "https://www.linkedin.com/in/akshay-wadkar-973576166/",
  },
  // {
  //   profileName: "Jitesh",
  //   position: "Senior Software Engineer",
  //   company: "Citi",
  //   company_logo:
  //     "https://1000logos.net/wp-content/uploads/2021/05/Citi-logo.png",
  //   review: "",
  //   linkedin: "https://www.linkedin.com/in/jitesh-soni-2241a7101/",
  // },
  // {
  //   profileName: "Vishal",
  //   position: "Tech Lead",
  //   company: "Oyo",
  //   company_logo:
  //     "https://seeklogo.com/images/O/oyo-rooms-logo-6C301512FE-seeklogo.com.png",
  //   review: "",
  //   linkedin: "https://www.linkedin.com/in/vishal-asrani/",
  // },
  // {
  //   profileName: "Vinayak",
  //   position: "Fresher",
  //   company: "Dayforce",
  //   company_logo:
  //     "https://d3bql97l1ytoxn.cloudfront.net/app_resources/296829/overview/img4479993099242896054.png",
  //   review: "",
  //   linkedin: "https://www.linkedin.com/in/vinayak-sareen/",
  // },
  // {
  //   profileName: "Deepti",
  //   position: "Fresher",
  //   company: "Airtel",
  //   company_logo:
  //     "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Bharti_Airtel_Logo.svg/2032px-Bharti_Airtel_Logo.svg.png",
  //   review: "",
  //   linkedin: "https://www.linkedin.com/in/deepti-yadav-126853192/",
  // },
];

export { Reivews };
