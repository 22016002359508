import React, { useState } from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { Navbar, Nav, Image } from "react-bootstrap";
import "../Styles/base.scss";
import Toast from "./Utilities/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CodebixLogo from "../Assets/CodebixLogo.png";

function Navigation() {
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCopyAlert = () => {
    setToast(true);
    setMessage("Phone number copied to clipboard!");
    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  return (
    <Router basename="/">
      <Navbar collapseOnSelect className="customNavbar" expand="lg">
        <Navbar.Brand className="customBrand">
          <Image
            fluid
            style={{
              width: "auto",
              height: "40px",
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: 2,
            }}
            src={CodebixLogo}
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" color="white" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link
              onClick={() => scrollToSection("reviews-section")}
              as={NavLink}
              className="customNav"
              to="/"
              activeClassName="active"
              exact
            >
              Reviews
            </Nav.Link>
            <Nav.Link
              onClick={() => scrollToSection("programs-section")}
              as={NavLink}
              className="customNav"
              to="/programs"
              activeClassName="active"
            >
              Programs
            </Nav.Link>
            <Nav.Link
              onClick={() => scrollToSection("features-section")}
              as={NavLink}
              className="customNav"
              to="/experience"
              activeClassName="active"
            >
              Features
            </Nav.Link>
            <Nav.Link
              onClick={() => scrollToSection("features-placements")}
              as={NavLink}
              className="customNav"
              to="/education"
              activeClassName="active"
            >
              Placements
            </Nav.Link>
            <Nav.Link
              onClick={() => scrollToSection("features-instructors")}
              as={NavLink}
              className="customNav"
              to="/instructors"
              activeClassName="active"
            >
              Instructors
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            <CopyToClipboard text="+918700352711">
              <div
                className="text-primary px-3 py-2 rounded d-flex flex-row align-items-center justify-content-center btn-white"
                onClick={handleCopyAlert}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-telephone-fill mr-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                  />
                </svg>
                +91-8700352711
              </div>
            </CopyToClipboard>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {toast && <Toast message={message} />}
    </Router>
  );
}

export default Navigation;
