import Marquee from "react-fast-marquee";

export default function PlacedCompanies() {
  return (
    <div className="container my-5">
      <h3 className="text-center">Where our students are placed!</h3>;
      <Marquee>
        <img
          src="https://www.vectorlogo.zone/logos/google/google-ar21.svg"
          alt=""
          className="mx-3"
          style={{ width: "150px" }}
        />
        <img
          src="https://www.vectorlogo.zone/logos/amazon/amazon-ar21.svg"
          alt=""
          className="mx-3"
          style={{ width: "150px" }}
        />
        <img
          src="https://www.vectorlogo.zone/logos/microsoft/microsoft-ar21.svg"
          alt=""
          className="mx-3"
          style={{ width: "150px" }}
        />
        <img
          src="https://www.vectorlogo.zone/logos/intel/intel-ar21.svg"
          alt=""
          className="mx-3"
          style={{ width: "150px" }}
        />
        <img
          src="https://www.vectorlogo.zone/logos/dell/dell-ar21.svg"
          alt=""
          className="mx-3"
          style={{ width: "150px" }}
        />
        <img
          src="https://www.vectorlogo.zone/logos/ey/ey-ar21.svg"
          alt=""
          className="mx-3"
          style={{ width: "150px" }}
        />
        <img
          src="https://www.vectorlogo.zone/logos/ibm/ibm-ar21.svg"
          alt=""
          className="mx-3"
          style={{ width: "150px" }}
        />
        <img
          src="https://www.vectorlogo.zone/logos/salesforce/salesforce-ar21.svg"
          alt=""
          className="mx-3"
          style={{ width: "150px" }}
        />
      </Marquee>
    </div>
  );
}
