import React from "react";
// import { Row } from "react-bootstrap";

const OfferBanner = (props) => {
  return (
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSdm8W4BZbSGxbUvCzhKRTiIDcYXABuYtZSeBFoDvrcKytUa6A/viewform?usp=sf_link"
      target="_blank"
      rel="noreferrer"
      // onClick={() => {
      //   props.setModalShow(true);
      // }}
      className="offerBanner m-0"
      style={{
        padding: "5px",
      }}
    >
      <label className="" style={{ color: "white", textAlign: "center" }}>
        Exclusive Offer: Get 20% Off on All Our Courses!
      </label>
    </a>
  );
};

export default OfferBanner;
