import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactTyped } from "react-typed";
import CODEBIX from "../../Assets/CODEBIX.pdf";
import Lines from "../../Assets/Lines.svg";

const Header = (props) => {
  return (
    <Container>
      <div
        className="header"
        style={{ backgroundColor: "white", marginTop: "2rem" }}
      >
        <Row>
          <Col sm={12} lg={6} style={{ backgroundColor: "white" }}>
            <h2 style={{ color: "black", paddingTop: "2rem", fontWeight: 400 }}>
              Land Your Dream Software
            </h2>
            <h1
              style={{ color: "#4395f5", fontWeight: 700 }}
              className="typing_text"
            >
              <ReactTyped
                strings={[" Job in 120 Days or Less"]}
                typeSpeed={80}
                backSpeed={50}
                loop
              />
            </h1>
            <p style={{ textAlign: "justify", fontWeight: 400 }}>
              Unlock interview success and transform into an industry-ready
              superstar with our DSA and System Design courses.
            </p>

            <div style={{ paddingTop: "1rem" }}>
              <Row>
                <Col sm={6} md={6} lg={6}>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdm8W4BZbSGxbUvCzhKRTiIDcYXABuYtZSeBFoDvrcKytUa6A/viewform?usp=sf_link"
                    target="_blank"
                    rel="noreferrer"
                    // onClick={() => {
                    //   props.setModalShow(true);
                    // }}
                    style={{
                      width: "100%",
                      height: "3rem",
                      fontSize: "20px",
                      fontWeight: 500,
                      border: "none",
                      borderRadius: "30px",
                    }}
                    className="btn btn-primary m-1"
                  >
                    Free Demo Class
                  </a>
                </Col>

                <Col sm={6} md={6} lg={6}>
                  <a
                    href={CODEBIX}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-outline-secondary m-1"
                    variant="outline-secondary"
                    style={{
                      width: "100%",
                      height: "3rem",
                      fontSize: "20px",
                      borderRadius: "30px",
                    }}
                  >
                    Download Full Syllabus
                  </a>
                </Col>
              </Row>
            </div>
          </Col>

          <Col sm={12} md={12} lg={6} className="hero_right">
            <div className="video_container">
              <iframe
                width="800"
                height="250"
                src="https://www.youtube.com/embed/bghsHtEgiF0"
                title="Free data structures and algorithms by Google software engineer"
                frameborder="0"
                allowfullscreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <img src={Lines} alt="lines" className="lines1" />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Header;
