import React from "react";
import "../../Styles/opportunity.scss";
import Tick from "../../Assets/Tick.svg";
import learning from "../../Assets/learning.png";
import job_interview from "../../Assets/interview.png";
import placement from "../../Assets/placement.png";

export default function OpportunityCard({ title, bulletPoints, image }) {
  return (
    <div className="opportunity_card mx-auto">
      <div className="opportunity_img">
        {image === "learning" && <img src={learning} alt="learning" />}
        {image === "interviews" && <img src={job_interview} alt="" />}
        {image === "placement" && <img src={placement} alt="placement" />}
      </div>
      <div className="oppor_heading">{title}</div>
      <ul>
        {bulletPoints &&
          bulletPoints.map((point, idx) => {
            return (
              <li key={idx}>
                <img
                  src={Tick}
                  alt=""
                  className="mr-1"
                  style={{ width: "10px" }}
                />
                {point}
              </li>
            );
          })}
      </ul>
    </div>
  );
}
