import { Card } from "react-bootstrap";
import { ReactTyped } from "react-typed";
import CODEBIX from "../../Assets/CODEBIX.pdf";

const Course = (props) => {
  const course = props.course;
  return (
    <div className="course_body">
      <Card className="course_card">
        {!course.isActive && (
          <div className="course_cover">
            <ReactTyped
              strings={["Coming Soon!"]}
              typeSpeed={80}
              backSpeed={50}
              loop
            />
          </div>
        )}
        <Card.Body>
          {course.isActive && <div className="course_label">500+ Enrolled</div>}
          <div className="course_heading">{course.title} </div>
          <div className="course_price">
            ₹{course.prodSP}
            <span>({course.prodMRP})</span>
            <div className="discount">{course.discount} OFF</div>
          </div>
          <div style={{ fontWeight: 500 }} className="mt-1">
            Curriculum summary
          </div>
          <ul className="mt-0 mb-4">
            {course.concepts.map((concept, index) => (
              <li key={index}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="gray"
                  className="bi bi-caret-right-fill mr-1"
                  viewBox="0 0 16 16"
                >
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
                {concept}
              </li>
            ))}
          </ul>
          <div className="btn_div">
            <a
              href={CODEBIX}
              target="_blank"
              rel="noreferrer"
              alt="CODEBIX"
              className="course_btn"
            >
              Download Syllabus
            </a>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdm8W4BZbSGxbUvCzhKRTiIDcYXABuYtZSeBFoDvrcKytUa6A/viewform?usp=sf_link"
              target="_blank"
              rel="noreferrer"
              //  onClick={() => props.setModalShow(true)}
              className="demo_btn"
            >
              Take Free Demo
            </a>
          </div>
        </Card.Body>
        <div className="circle1"></div>
        <div className="circle2"></div>
        <div className="circle3"></div>
        <div className="rectangle1"></div>
        <div className="rectangle2"></div>
      </Card>
    </div>
  );
};

export default Course;
